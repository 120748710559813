import * as React from "react"
import { graphql } from 'gatsby'

import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"
import AnimationWrapper from "../components/animationWrapper"
import TileSection from "../components/tileSection"
import LightboxImageGallery from "../components/lightboxImageGallery"
import ContactSection from "../components/contactSection"
import Layout from "../components/layout"

import "./galeria.css"

const RealizacjePage = ( {data, location} ) => {

  return (

    <Layout location={location}>

      <Seo title={data.page.frontmatter.title} />

      <AnimationWrapper>
        <Header pageName="Galeria" data={data.header} />
      </AnimationWrapper>

      <section id="gallery-videos" className="gallery">
        <Container>
          <LightboxImageGallery data={data.page.frontmatter.gallery_video} />
        </Container>
      </section>

      {data.page.frontmatter.galleries_photo.map((gallery, key) => (
        <section className="gallery">
          <Container>
            <LightboxImageGallery key={key} data={gallery} />
          </Container>
        </section>
      ))}

      <section id="contact-data" className="page-section">
        <ContactSection data={data.page.frontmatter.contact_section} />
      </section>

    </Layout>
  )
}

export const pageQuery = graphql`
  query{
    page: mdx(frontmatter: { slug: { eq: "realizacje" }, type: { eq: "page" } }) {
      frontmatter{
        title
        gallery_video{
          title
          title_visible
          type
          button_text
          button_url
          button_style
          photos{
            title
            description
            image{
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {fit: COVER},
                  webpOptions: {quality: 95}
                )
              }
            }
            status
          }
        }
        galleries_photo{
          title
          title_visible
          type
          button_text
          button_url
          button_style
          photos{
            title
            description
            image{
              childImageSharp {
                gatsbyImageData(
                  transformOptions: {fit: COVER},
                  webpOptions: {quality: 95}
                )
              }
            }
            status
          }
        }
        contact_section{
          title
          description
          type
          fields
          submit_button_style
          image{
            childImageSharp{
              gatsbyImageData(
                webpOptions: {quality: 95}
              )
            }
          }
        }
      }
    }
    header: file(relativePath: {eq: "header-dark.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          quality: 95
          transformOptions: {fit: COVER}
          webpOptions: {quality: 95}
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

export default RealizacjePage
